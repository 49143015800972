import React, { useEffect, useState } from 'react'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { Link } from 'react-router-dom'
import './NewLoan.css'
import { useForm } from 'react-hook-form'
// import { Submit } from '../../Service';
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'
import NewLoanUdata from './NewLoanUdata'


const NewLoan = () => {
 
  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState(null); // State to store user data
  const [accountNo, setAccountNo] = useState(''); // State to store account number
  const [chequeNoError, setChequeNoError] = useState(false);
  const [DateError, setFdDateError] = useState(false);
  // Add a state to hold form data
 
  const [rateOfInterest, setRateOfInterest] = useState('');
  const [formData, setFormData] = useState({
    accountNo: '',
    grossSalary: '',
    netSalary: '',
    loanAmount: '',
    rateOfInterest: '',
    date: '',
    principleAmount: '',
    chequeNo: '',
    loanType: '',
    rateOfInterest: '',

    // date: '',
    // principleAmount: '',
    // interestAoumnt: 0,
    // totalFdamount: 0,
  });

  // https://103.50.212.98/api/RegularLoan/post
  // https://103.50.212.98/api/RegularLoan/accountNo?accountNo=${accountNo}

  useEffect(() => {
    const fetchrateOfInterest = async () => {
      try {
        const response = await axios.get('https://103.50.212.98/api/RegularLoan/getInterestRate');
        const { result } = response.data;
        setRateOfInterest(result);
      } catch (error) {
        console.log('Error fetching FD Certificate Number:', error);
      }
    };

    fetchrateOfInterest(); // Trigger the API call when component mounts
  }, []); // Empty dependency array means this effect runs only once after the initial render


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handlerateOfInterest = (e) => {
    const { value } = e.target;
    setRateOfInterest(value); // Update the fdCertificateNo state directly
  };



  const fetchUserDetails = async (accountNo) => {
    try {
      const res = await axios.get(`https://103.50.212.98/api/RegularLoan/accountNo?accountNo=${accountNo}`);
      const userData = res.data.result;
      setUserData(userData);
      // Update accountName based on fetched user data
      setFormData({
        ...formData,
        fullName: userData.fullName,
        insurance: userData.insurance,
        loanBalanceAmount: userData.loanBalanceAmount,
        share: userData.share,
        principleAmount: userData.principleAmount,
      
      });
    } catch (error) {
      console.log(error);
    }
  };



  // Function to handle form submission
const handleSubmit = async (event) => {
  event.preventDefault();

   // Check if FD amount is empty
   if (!formData.chequeNo) {
    setChequeNoError(true);
  } else {
    setChequeNoError(false);
  }

  // Check if FD date is empty
  if (!formData.date) {
    setFdDateError(true);
  } else {
    setFdDateError(false);
  }

  // If either FD amount or FD date is empty, exit the function
  if (!formData.chequeNo || !formData.date) {
    return;
  }

  try {
    // Add fdCertificateNo to the formData object
    const formDataWithrateOfInterest = {
      ...formData,
      rateOfInterest: rateOfInterest,
    };

    const response = await axios.post('https://103.50.212.98/api/RegularLoan/post', formDataWithrateOfInterest);
    console.log('Form data submitted successfully:', response.data);
    alert("Form data submitted successfully")
    // Handle success or navigate to another page
    // Reset the form fields to initial empty state
    setFormData({
      accountNo: '',
    grossSalary: '',
    netSalary: '',
    loanAmount: '',
    rateOfInterest: '',
    date: '',
    principleAmount: '',
    chequeNo: '',
    loanType: '',
    rateOfInterest: '',
    });
  } catch (error) {
    console.error('Error submitting form data:', error);
    // Handle error state or show error message
  }
};











  const fetchUsers = async (url) => {
    try {
      const res = await axios.get(url); // Use axios.get instead of fetch
      const Tdata = res.data; // Axios response data is directly accessible as res.data
      console.log(Tdata, " new loan table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };

  const API = "https://103.50.212.98/api/RegularLoan/get";

  useEffect(() => {
    fetchUsers(API);
  }, []);


  // Prevent accidental value changes on scroll
  const handleScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div>
      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
   
   
   
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>
        </div>
        {/* <!-- #END# Search Bar -->
   
   
   
   
  <!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}



        {/* sideBar */}


        <AdminSideBar />
        {/* end sideBar */}

        <section className="content" onScroll={handleScroll}>
          <Link to="/AdminDashBoard">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">

            </div>

            {/* form starts */}
            {/* <!-- Masked Input --> */}

            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>New Loan</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">
                        <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="DedictionForm">



                          <div className="col-md-8">
                            <b>Account Number (खाते क्रमांक)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                              <input
                              type="number"
                              name='accountNo'
                              className="form-control date"
                              placeholder="Enter Account Number"
                              value={formData.accountNo}
                                onChange={handleInputChange}
                            />
                              <button
                                type="button"
                                onClick={() => fetchUserDetails(formData.accountNo)}
                                className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton"
                              >
                                Search
                              </button>
                              </div>
                               
                            </div>
                          </div>
                          {/* <button type="button" className="btn bg-indigo btn-block btn-lg waves-effect primaryButton">Search</button> */}
                          {/* <button type="button" className="btn btn-primary ">Search</button> */}
                              





                          <div className="col-md-8">
                            <b>Full Name (पूर्ण नाव)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">

                              <input
                             type="text"
                             name='fullName'
                             className="form-control date"
                             placeholder="Enter Full Name"
                            
                             value={formData.fullName}
                             readOnly
                           />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Insurence</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">

                              <input
                             type="number"
                             name='insurance'
                             className="form-control date"
                             placeholder="Enter Insurence"
                           
                             value={formData.insurance}
                             readOnly
                           />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>Loan Balance Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">

                              <input
                             type="number"
                             name='loanBalanceAmount'
                             className="form-control date"
                             placeholder="Enter Loan Amount"
                             value={formData.loanBalanceAmount}
                             readOnly
                             
                           />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>Shares</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">

                              <input
                             type="number"
                             name='share'
                             className="form-control date"
                             placeholder="Enter Shares"
                             value={formData.share}
                             readOnly
                           />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b> Principal Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  name='principleAmount'
                                  placeholder="Enter Principal Amount"
                                  value={formData.principleAmount}
                                  readOnly
                                />

                              </div>
                            </div>
                          </div>
                           



                          <div className="col-md-8">
                            <b>Net Salary</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='netSalary'
                                  className="form-control date"
                                  placeholder='Enter Net Salary (in Rs)'
                                  value={formData.netSalary}
                                  onChange={handleInputChange}
                                
                                />
                                {/* <button type="button" className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton">Check</button> */}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>Gross Salary</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='grossSalary'
                                  className="form-control date"
                                  placeholder='Enter Gross Salary (in Rs)'
                                  value={formData.grossSalary}
                                   onChange={handleInputChange}
                                />
                                 </div>
                            </div>
                          </div>
                          {/* <button type="button" className="btn btn-primary checkButton ">Check</button> */}


                         

                          <div className="col-md-8">
                            <b>Loan Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                   name='loanAmount'
                                  className="form-control date"
                                  placeholder="Enter Loan Balance"
                                  value={formData.loanAmount}
                                   onChange={handleInputChange}
                                />

                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                            <b>Rate Of Interest</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                   name='rateOfInterest'
                                  className="form-control date"
                                  placeholder="Enter Loan Balance"
                                  value={rateOfInterest} // Bind input value directly to the state
                                    onChange={handlerateOfInterest} 
                                    readOnly 
                                />

                              </div>
                            </div>
                          </div>


                          <div className="col-md-8">
                              <b>Date</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                    type="date"
                                    name='date'
                                    // className="form-control "
                                    className={`form-control ${DateError ? 'is-invalid' : ''}`}
                                   
                                   
                                    value={formData.date}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                           {DateError && <p style={{ color: "red" }}>Date is required</p>}
                          {/* <p style={{ color: "red" }}>{errors?.fdAmount?.message}</p> */}
                         
                            </div>



                      





                          <div className="col-md-8">
                            <b>Total Principal Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  name='principleAmount'
                                  placeholder="Enter Total Principal Amount"
                                  value={formData.principleAmount}
                                   onChange={handleInputChange}
                                />

                              </div>
                            </div>
                          </div>



                         

                          <div className="col-md-8">
                              <b>Cheque Number</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                    type="number"
                                    name='chequeNo'
                                    // className="form-control "
                                    className={`form-control ${chequeNoError ? 'is-invalid' : ''}`}
                                   
                                   
                                    value={formData.chequeNo}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                           {chequeNoError && <p style={{ color: "red" }}>Cheque number is required</p>}
                          {/* <p style={{ color: "red" }}>{errors?.fdAmount?.message}</p> */}
                         
                            </div>

                         

                         



                         


                          <Button type="submit" variant='primary' className="btn bg-indigo btn-block btn-lg waves-effect">Apply Loan</Button>
                       </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}

            {/* Table start */}

            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>New Loan Member List</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                        
                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}



                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                           
                        >
                          
                          <tbody>
                            <NewLoanUdata Users={users} />

                      
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}
          </div>
        </section>
      </div>
    </div>
  )
}

export default NewLoan
