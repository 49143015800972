import React, { useEffect, useState } from 'react'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { Link } from 'react-router-dom'
import './LoanAmountCredit.css'
import { useForm } from 'react-hook-form'
import { Submit } from '../../Service';
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'
import LoanAmountData from './LoanAmountData'


const LoanAmountCredit = () => {

  // const {handleSubmit,register,reset,formState:{errors}}=useForm();
  
  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState(null); // State to store user data (auto fill fields)
  const [accountNo, setAccountNo] = useState(''); // State to store account number
  
  const [creditLoanAMountError, setCreditLoanAMountError] = useState(false);
  const [paymentDateError, setPaymentDateError] = useState(false);
  
  
  // Add a state to hold form data
  const [formData, setFormData] = useState({
    accountNo: '',
    fullName: '',
    paymentDate: '',
    loanBalanceAmount: '',
    creditLoanAMount: '',
    balanceAfterDeduction: '',
    desciption: '',
    // chequeNo: '',
   // reciptNo: '',
    // fdId: 0,
    // fdYear: new Date().toISOString(),
    // fdCloseDate: new Date().toISOString(),
    // isDelete: true,
    // changeInterestId: 0,
    // interestAoumnt: 0,
    // totalFdamount: 0,
  });
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchUserDetails = async (accountNo) => {
    try {
      const res = await axios.get(`https://103.50.212.98/api/RegularLoanCredit/accountNo?accountNo=${accountNo}`);
      const userData = res.data.result;
      setUserData(userData);
      // Update accountName based on fetched user data
      setFormData({
        ...formData,
        fullName: userData.fullName,
        insurance: userData.insurance,
        loanBalanceAmount: userData.loanBalanceAmount,
        share: userData.share,
        principleAmount: userData.principleAmount,
       
      });
    } catch (error) {
      console.log(error);
    }
  };




  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submitted!"); // Add this line
    // Check if FD amount is empty
    if (!formData.creditLoanAMount) {
      setCreditLoanAMountError(true);
    } else {
      setCreditLoanAMountError(false);
    }
  
    // Check if FD date is empty
    if (!formData.paymentDate) {
      setPaymentDateError(true);
    } else {
      setPaymentDateError(false);
    }
  
    // If either FD amount or FD date is empty, exit the function
    if (!formData.creditLoanAMount || !formData.paymentDate) {
      return;
    }
  
    try {
      const response = await axios.post('https://103.50.212.98/api/RegularLoanCredit/LoanCredit', formData);
      console.log('Form data submitted successfully:', response.data);
      alert("Form data submitted successfully")
      // Handle success or navigate to another page
      // Reset the form fields to initial empty state
      setFormData({
        accountNo: '',
        fullName: '',
        paymentDate: '',
        loanBalanceAmount: '',
        creditLoanAMount: '',
        balanceAfterDeduction: '',
        desciption: '',
        // chequeNo: '',
       // reciptNo: '',
      });
    } catch (error) {
      console.error('Error submitting form data:', error);
      // Handle error state or show error message
    }
  };
    



 






  // table data code
  const fetchUsers = async (url) => {
    try {
      const res = await axios.get(url); // Use axios.get instead of fetch
      const Tdata = res.data; // Axios response data is directly accessible as res.data
      console.log(Tdata, " Add FD table data"); // Log the data here to inspect its structure
      setUsers(Tdata); // Set the fetched data to the users state
    } catch (error) {
      console.log(error);
    }
  };

  const API = "https://103.50.212.98/api/RegularLoanCredit";

  useEffect(() => {
    fetchUsers(API);
  }, []);

   // Prevent accidental value changes on scroll
   const handleScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
 



  


 

  return (
    <div>

      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
   
   
   
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>

        </div>
        {/* <!-- #END# Search Bar -->
   
   
   
   
  <!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}



        {/* sideBar */}


        <AdminSideBar />
        {/* end sideBar */}

        <section className="content">
          <Link to="/AdminDashBoard">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">

            </div>

            {/* form starts */}
            {/* <!-- Masked Input --> */}

            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="DedictionForm">


                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="card">
                      <div className="header">
                        <h2>Loan Amount Credit Form</h2>
                      </div>
                      <div className="body">
                        <div className="demo-masked-input">
                          <div className="row clearfix">



                          <div className="col-md-8">
                            <b>Account Number (खाते क्रमांक)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                              <input
                                  type="number"
                                  name='accountNo'
                                  className="form-control date"
                                  placeholder="Enter Account Number"
                                  value={formData.accountNo}
                                  onChange={handleInputChange}
                                />
                                <button type="button"
                               onClick={() => fetchUserDetails(formData.accountNo)}
                                  className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton">Search</button>
                              </div>
                              {/* {errors.accountNo && errors.accountNo.type === "pattern" && (
                                  <div className="invalid-feedback text-red">Please enter only numbers</div>
                                )} */}
                            </div>
                          </div>


                          {/* <button type="button" className="btn btn-primary ">Search</button> */}



                          <div className="col-md-8">
                            <b>Full Name (पूर्ण नाव)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='fullName'
                                  className="form-control date"
                                  placeholder="Enter Full Name (पूर्ण नाव)"
                                  value={formData.fullName}
                                  readOnly
                               
                                 />
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-8">
                            <b>Insurence</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                              <input
                             type="number"
                             name='insurance'
                             className="form-control date"
                             placeholder="Enter Insurence"
                             value={formData.insurance}
                             readOnly
                          

                           />
                              </div>
                            </div>
                          </div> */}


                          <div className="col-md-8">
                            <b>Loan Balance</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">

                              <input
                             type="number"
                             name='loanBalanceAmount'
                             className="form-control date"
                             placeholder="Enter Loan Amount"
                             value={formData.loanBalanceAmount}
                              readOnly
                            onChange={handleInputChange}
                              
                           />
                              </div>
                            </div>
                          </div>


                          {/* <div className="col-md-8">
                            <b>Shares</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">

                              <input
                             type="number"
                             name='share'
                             className="form-control date"
                             placeholder="Enter Shares"
                             value={formData.share}
                             readOnly
                           />
                              </div>
                            </div>
                          </div> */}


                          <div className="col-md-8">
                            <b> Principal Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  name='principleAmount'
                                  placeholder="Enter User Principal Amount"
                                  value={formData.principleAmount}
                                  readOnly
                                />

                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>Date</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                  <input
                                    type="date"
                                    name='paymentDate'
                                    className={`form-control ${paymentDateError ? 'is-invalid' : ''}`}
                                    // {...register("paymentDate", {required: 'Date is require'})}
                                    value={formData.paymentDate}
                                    onChange={handleInputChange}
                                 />
                              </div>
                            </div>
                            {paymentDateError && <p style={{ color: "red" }}>Date is required</p>}
                          </div>



                        



                          {/* <div className="col-md-8">
                            <b>Prev. Loan Amount (मागील कर्जाची रक्कम)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='loanBalanceAmount'
                                  className="form-control date"
                                  placeholder="Enter Prev. Loan Amount (मागील कर्जाची रक्कम)"
                                  value={formData.loanBalanceAmount}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div> */}



                          <div className="col-md-8">
                            <b>Credit Loan Amount (कर्जाची रक्कम)</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                  <input
                                    type="number"
                                    name='creditLoanAMount'
                                    className={`form-control ${creditLoanAMountError ? 'is-invalid' : ''}`}
                                    placeholder="Enter Credit Loan Amount (कर्जाची रक्कम)"
                                    value={formData.creditLoanAMount}
                                    onChange={handleInputChange}
                                  />
                              </div>
                            </div>
                            {creditLoanAMountError && <p style={{ color: "red" }}>this field is required</p>}
                           </div>


                          {/* <div className="col-md-8">
                            <b>Loan Balance After Deduction</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='balanceAfterDeduction'
                                  className="form-control date"
                                  placeholder="Enter Loan Balance After Deduction"
                                  value={formData.balanceAfterDeduction}
                                  onChange={handleInputChange}

                                />
                                {/* <button type="button" className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton">Check</button> */}
                              {/* </div>
                            </div>
                          </div>  */}

                          {/* <button type="button" className="btn btn-primary loanAfterDeduction_btn">Check</button> */}



                          <div className="col-md-8">
                            <b>Cheque Number/reciept number</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="text"
                                  name='desciption'
                                  className="form-control date"
                                  placeholder="Enter Cheque Number"
                                  value={formData.desciption}
                                   onChange={handleInputChange}

                                />
                              </div>
                            </div>
                          </div>



                          {/* <div className="col-md-8">
                          <b>Discription</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                            
                              <div className="col-8">
                                        <input className="form-control"
                                        type="text" id="photo" name="desciption" multiple=""
                                        value={formData.desciption}
                                        onChange={handleInputChange}
                                        />
                                    </div>
                            </div>
                          </div>
                        </div>  */}


                       
                            <Button type="submit" variant='primary' className="btn btn-block btn-lg waves-effect">Submit</Button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </Form.Group>
            </Form>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}


            {/* Table start */}

            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Loan Amount Credit</h2>
                   
                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                       

                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}








                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                         
                         
                          <tbody>
                            <LoanAmountData Users={users} />
                          </tbody>
                        </table>
                       
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}






          </div>
        </section>
      </div>
    </div>
  )
}

export default LoanAmountCredit
