import React, { useState } from 'react';  
import AdminTopBar from '../../AdminTopBar/AdminTopBar';  
import AdminSideBar from '../../AdminSideBar/AdminSideBar';  
import { Link } from 'react-router-dom';  
import { useForm } from 'react-hook-form';  
import axios from 'axios';  
import { Button, Form, Spinner } from 'react-bootstrap';  
import jsPDF from 'jspdf';  
import 'jspdf-autotable';  
import * as XLSX from 'xlsx';  

const DeductionListCompare = () => {  
  const { handleSubmit, register, formState: { errors } } = useForm();  
  const [loading, setLoading] = useState(false);  

  

  const onSubmit = async (data) => {  
    setLoading(true);  
    const bankListFile = data.bankList[0];  
    const departmentListFile = data.departmentList[0];  

    const readFile = (file) => {  
      return new Promise((resolve, reject) => {  
        const reader = new FileReader();  
        reader.onload = (event) => {  
          const binaryStr = event.target.result;  
          const workbook = XLSX.read(binaryStr, { type: 'binary' });  
          const sheetName = workbook.SheetNames[0];  
          const sheet = workbook.Sheets[sheetName];  
          const jsonData = XLSX.utils.sheet_to_json(sheet);  
          resolve(jsonData);  
        };  
        reader.onerror = (error) => reject(error);  
        reader.readAsBinaryString(file);  
      });  
    };  

    try {  
      const [bankList, departmentList] = await Promise.all([  
        readFile(bankListFile),  
        readFile(departmentListFile),  
      ]);  

      const payload = {  
        bankList,  
        departmentList,
         date: data.date,  
      };  
      console.log("payload testing", typeof(payload.bankList[0]));

      const response = await axios.post(  
        'https://103.50.212.98/api/DeductionListCompare/DeductionListCompare',  
        payload,  
        {  
          headers: {  
            'Content-Type': 'application/json',  
          }  
        }  
      );  

      console.log("API Response:", response); // Log response  

      if (response.data && response.data.result) {  
        console.log("API response",response.data);
         generatePDF(response.data, data.date);  
      } else {  
        console.error('Invalid response data');  
      }  
    } catch (error) {  
      console.error('Error:', error);  
    } finally {  
      setLoading(false);  
    }  
  };  

  const generatePDF = (data, date) => {  
    const doc = new jsPDF();  

    doc.setFontSize(14);  
    doc.text("E-Patsanstha - Deduction Compare Print", doc.internal.pageSize.getWidth() / 2, 20, { align: "center" });  

    doc.setFontSize(12);  
    const secondHeading = [  
      "Swami Ramanand Teerth Marathwada Karmchari Sahakari Patsanstha Maryadit,",  
      "Vishnupuri, Nanded",  
      "Pharak Yadi Mahe"  
    ];  

    secondHeading.forEach((line, index) => {  
      doc.text(line, doc.internal.pageSize.getWidth() / 2, 30 + (index * 10), { align: "center" });  
    });  

    doc.setFontSize(10);  
    doc.text(`Date: ${date}`, doc.internal.pageSize.getWidth() / 2, 60, { align: "center" });  

    doc.text("", 10, 70);  

    const tableColumn = ["Account No", "Bank Total Amount", "Department Total Amount", "Difference Amount"];  
    const tableRows = data.result.slice(0, -1).map((row) => [  
      row.accountNo,  
      row.bankTotalAmount,  
      row.departmentTotalAmount,  
      row.diffranceAmount,  
    ]);  

    const totalRow = data.result[data.result.length - 1];  
    tableRows.push([  
      totalRow.accountNo,  
      totalRow.bankTotalAmount,  
      totalRow.departmentTotalAmount,  
      totalRow.diffranceAmount,  
    ]);  

    doc.autoTable({  
      head: [tableColumn],  
      body: tableRows,  
      startY: 80,  
    });  

    doc.save('DeductionListCompareReport.pdf');  
  };  

  return (  
    <div>  
      <div className="theme-red">  
        <AdminTopBar />  
        <AdminSideBar />  
        <section className="content">  
          <Link to="/AdminDashBoard">  
            <h4>DASHBOARD</h4>  
          </Link>  
          <div className="container-fluid">  
            <Form onSubmit={handleSubmit(onSubmit)}>  
              <Form.Group className="mb-3" controlId="DedictionForm">  
                <div className="row clearfix">  
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">  
                    <div className="card">  
                      <div className="header">  
                        <h2>Deduction List Compare</h2>  
                      </div>  
                      <div className="body">  
                        <div className="demo-masked-input">  
                          <div className="row clearfix">  
                            <div className="col-md-8">  
                              <b>Select Start Date</b>  
                              <div className="input-group">  
                                <span className="input-group-addon">  
                                  <i className="material-icons">event_note</i>  
                                </span>  
                                <div className="form-line">  
                                  <Form.Control  
                                    type="date"  
                                    {...register("date", { required: "Please enter Start Date" })}  
                                  />  
                                </div>  
                              </div>  
                              <p style={{ color: "red" }}>{errors?.date?.message}</p>  
                            </div>  
                            <div className="col-md-8">  
                              <b>Deduction List (Society List)</b>  
                              <div className="input-group">  
                                <span className="input-group-addon">  
                                  <i className="material-icons">attach_file</i>  
                                </span>  
                                <div className="form-line">  
                                  <input  
                                    type="file"  
                                    className="form-control"  
                                    {...register("bankList", { required: "Please upload the bank list file" })}  
                                  />  
                                </div>  
                              </div>  
                              <p style={{ color: "red" }}>{errors?.bankList?.message}</p>  
                            </div>  
                            <div className="col-md-8">  
                              <b>Deduction List (Salary Department)</b>  
                              <div className="input-group">  
                                <span className="input-group-addon">  
                                  <i className="material-icons">attach_file</i>  
                                </span>  
                                <div className="form-line">  
                                  <input  
                                    type="file"  
                                    className="form-control"  
                                    {...register("departmentList", { required: "Please upload the department list file" })}  
                                  />  
                                </div>  
                              </div>  
                              <p style={{ color: "red" }}>{errors?.departmentList?.message}</p>  
                            </div>  
                            <Button type="submit" variant="primary" className="btn btn-block btn-lg waves-effect">  
                              {loading ? (  
                                <>  
                                  <Spinner  
                                    animation="border"  
                                    role="status"  
                                    size="sm"  
                                    style={{ marginRight: "5px" }}  
                                  >  
                                    <span className="sr-only">Loading...</span>  
                                  </Spinner>  
                                  Generating PDF...  
                                </>  
                              ) : "Report"}  
                            </Button>  
                          </div>  
                        </div>  
                      </div>  
                    </div>  
                  </div>  
                </div>  
              </Form.Group>  
            </Form>  
          </div>  
        </section>  
      </div>  
    </div>  
  );  
};  

export default DeductionListCompare;