import axios from 'axios';
import React, { useEffect, useState } from 'react'

const CloseFDmodal = ({ handleEdit ,handleInputChange,formData,handleUpdateValues,disabled}) => {
  const [fdCloseDate, setFdCloseDate] = useState('');
  const [fdAmount, setFdAmount] = useState('');
  const [interestAmount, setInterestAmount] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [days, setDays] = useState('');

  const handleDelete = () => {
    const deleteData = {
      fdId: formData.fdId,
      fdDate: formData.fdDate,
      fdCloseDate: fdCloseDate,
      fdAmount: fdAmount,
      interestAmount: interestAmount,
      totalAmount: totalAmount,
      days: days,
      chequeNo: formData.chequeNo,
      reciptNo: formData.reciptNo,
    };

    console.log("FD deleted data", deleteData);
    axios
      .delete('https://103.50.212.98/api/FdDetails/delete', { data: deleteData })
      .then((response) => {
        console.log('Delete API response:', response.data);
        alert('Deleted successfully');
        handleResetForm();
        // Update the table with the new values
        handleInputChange({ target: { name: 'fdCloseDate', value: fdCloseDate } });
        handleInputChange({ target: { name: 'totalAmount', value: totalAmount } });
      })
      .catch((error) => {
        console.error('Error deleting FD:', error);
      });
  };

  const handleResetForm = () => {
    setDays('');
    setFdAmount('');
    setInterestAmount('');
    setTotalAmount('');
  };

  const handleDateButtonClick = () => {
    axios
      .get(`https://103.50.212.98/api/FdDetails/GetInterestAmountForClose`, {
        params: {
          FdId: formData.fdId,
          UserDate: fdCloseDate,
        },
      })
      .then((response) => {
        const { fdAmount, interestAmount, totalAmount, days } = response.data.result;
        setFdAmount(fdAmount);
        setInterestAmount(interestAmount);
        setTotalAmount(totalAmount);
        setDays(days);
      })
      .catch((error) => {
        console.error('Error fetching interest amount:', error);
      });
  };

  const handleEndDateChange = (e) => {
    setFdCloseDate(e.target.value);
  };
  return (
    <div>
      {/* <i onClick={handleEdit} type="button" className='material-icons edit-icon' data-toggle="modal" data-target="#exampleModal">edit_sweep</i> */}
      <i   
        onClick={disabled ? null : handleEdit}   
        type="button"   
        className={`material-icons edit-icon ${disabled ? 'disabled' : ''}`}   
        data-toggle={disabled ? '' : 'modal'}   
        data-target={disabled ? '' : '#exampleModal'}  
      >  
        edit_sweep  
      </i> 
      {/* <!-- Modal --> */}
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Close FD</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              {/* modal form start */}
              {/* {fdDetails ? ( */}
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="card modalFormCard">
                    <div className="header">
                      <h2>Close FD</h2>
                    </div>
                    <div className="body">
                      <div className="demo-masked-input">
                        <div className="row clearfix">

                          <div className="col-md-8">
                            <b>ID</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder="Enter id"
                                  value={formData.fdId}
                                  onChange={(e) => handleInputChange(e)}
                                  name="fdId" id="fdId"
                                />
                              </div>
                            </div>
                          </div>

                         <div className="col-md-8">
                            <b>Start Date</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">date_range</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="date"
                                  className="form-control date"
                                  placeholder="Select Start Date"
                                  value={formData.fdDate ? formData.fdDate.split('T')[0] : ''}
                                  readOnly
                                  name="fdDate" id="fdDate"
                                  onChange={(e) => handleInputChange(e)}

                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>End Date</b>
                            <div className="input-group" style={{ display: 'flex', alignItems: 'center' }}>
                              <span className="input-group-addon">
                                <i className="material-icons">date_range</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="date"
                                  className="form-control date"
                                  placeholder="Select End Date"
                                  value={fdCloseDate}
                                  onChange={handleEndDateChange}
                                  name="fdCloseDate" id="fdCloseDate"

                                />
                              </div>
                              <button
                                  type="button"
                                  className="btn bg-indigo"
                                  style={{ marginLeft: '10px' }}
                                  onClick={handleDateButtonClick}
                                >
                                  Date
                                </button>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>Total Days</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder='Enter total days'
                                  // value={formData.days}
                                  value={days}
                                  onChange={(e) => handleInputChange(e)}
                                  name="days" id="days"

                                />
                              </div>
                            </div>
                          </div>



                          <div className="col-md-8">
                            <b>Interest Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder='Enter total days'
                                  value={interestAmount}
                                  onChange={(e) => handleInputChange(e)}
                                  name="interestAoumnt" id="interestAoumnt"

                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <b>FD Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder='Enter FD amount'
                                  value={fdAmount}
                                  onChange={(e) => handleInputChange(e)}
                                  name="fdAmount" id="fdAmount"
                                />
                              </div>
                            </div>
                          </div>

                         

                          <div className="col-md-8">
                            <b>Total Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder='Enter Total amount'
                                  value={totalAmount}
                                  onChange={(e) => handleInputChange(e)}
                                  name="totalAmount" id="totalAmount"

                                />
                              </div>
                            </div>
                          </div>


                          <button type="button" onClick={handleDelete} className="btn bg-indigo btn-block btn-lg waves-effect" >Submit</button>
                          <button type="button" className="btn bg-indigo btn-block btn-lg waves-effect closeButton" data-dismiss="modal">Close</button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ) : null} */}
              {/* Modal form ends */}


            </div>
          </div>
        </div>
      </div>
      {/* Modal End */}
    </div>
  )
}

export default CloseFDmodal

