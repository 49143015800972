import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import { useForm } from 'react-hook-form'
// import { Submit } from '../../../Service'
import { Submit } from '../../Service';
import { Button, Form } from 'react-bootstrap'
import RDUserData from './RDUserData'
import axios from 'axios'

const RDReport = () => {
  const [loanData, setLoanData] = useState([]);
  const [accountNo, setAccountNo] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleSubmit = async () => {
    try {
      let apiUrl = 'https://103.50.212.98/api/Report/RDReport?';
      if (accountNo) {
        apiUrl += `AccountNo=${accountNo}&`;
      }
      apiUrl += `StartDate=${startDate}&EndDate=${endDate}`;

      const response = await axios.get(apiUrl);
      setLoanData(response.data.result);
    } catch (error) {
      console.error('Error fetching loan data:', error);
    }
  };

  return (
    <div>
          <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
   
   
   
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>
       
        </div>
        {/* <!-- #END# Search Bar -->
   
   
   
   
  <!-- Top Bar --> */}
       <AdminTopBar/>
        {/* <!-- #Top Bar --> */}


        
{/* sideBar */}


<AdminSideBar  />
{/* end sideBar */}

        <section className="content">
             <Link to="/AdminDashBoard">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">
            
            </div>

          
              {/* form starts */}
            {/* <!-- Masked Input --> */}

             

                
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>RD Report</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">


                        <div className="col-md-8">
                          <b>Account Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">event_note</i>
                            </span>
                            <div className="form-line">
                            <input
                                type="number"
                                className="form-control time24"
                                placeholder="Enter Account Number"
                                value={accountNo}
                                onChange={(e) => setAccountNo(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>


                        <div className="col-md-8">
                          <b>Select Start Date</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">event_note</i>
                            </span>
                            <div className="form-line">
                            <input
                                type="date"
                                className="form-control time24"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                              />
                            {/* <Form.Control
                                    type="date"
                                    className="form-control time24"


                                    {...register("startDate", { required: "please enter Start Date" })}
                                  /> */}
                            </div>
                          </div>
                          {/* <p style={{ color: "red" }}>{errors?.startDate?.message}</p> */}

                        </div>


                        <div className="col-md-8">
                          <b>Select End Date</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">event_note</i>
                            </span>
                            <div className="form-line">
                            <input
                                type="date"
                                className="form-control time24"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                              />
                            {/* <Form.Control
                                    type="date"
                                    className="form-control time24"
                                    {...register("endDate", { required: "please enter End Date" })}
                                  /> */}
                            </div>
                          </div>
                          {/* <p style={{ color: "red" }}>{errors?.endDate?.message}</p> */}

                        </div>

                       

                        <Button type="submit" onClick={handleSubmit} variant='primary' className="btn btn-block btn-lg waves-effect">Report</Button>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

           
          

            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}
 {/* Table start */}

 <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>RD Report data</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >



                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}


                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                          <RDUserData loanData={loanData} />
                        </table>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}










        



          </div>
        </section>
      </div>
    </div>
  )
}

export default RDReport
