import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { useForm } from 'react-hook-form'
import { Submit } from '../../Service';
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'
import InsurenceData from './InsurenceData'


const Insurence = () => {
  
  const { handleSubmit, register, reset, formState: { errors } } = useForm();
  // const [users, setUsers] = useState([])
  const [userData, setUserData] = useState(null); // State to store user data (auto fill fields)
  const [accountNo, setAccountNo] = useState(''); // State to store account number
  // Add a state to hold form data
  const [formData, setFormData] = useState(null);




  // Function to handle form submission
const onSubmit = async (formData) => {
  console.log("Insurance form data", formData);


 // Fetch user details to get fullName if not present
 if (!formData.fullName && accountNo) {
  await fetchUserDetails(accountNo);
  // Update the form data with the fullName from userData
  formData.fullName = userData ? userData.fullName : '';
}

  // Prepare data to be sent to the API
  const postData = {
    accountNo: formData.accountNo,
    fullName: formData.fullName,
    date: formData.date,
    insuranceAmount: formData.insuranceAmount,
    totalInsuranceAmount: userData ? userData.totalAmount : '', // Assuming this comes from userData
    recepitNo: formData.recepitNo,
  };

  axios.post('https://103.50.212.98/api/Insurance/post', postData)
    .then((response) => {
      console.log('Response:', response.data);
      setFormData(formData);
      reset(); // Clear the form after successful submission
      alert("Form Submitted Successfully");
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle error response here
    });
};

  



  // Update accountNo state when input changes
const handleAccountNoChange = (event) => {
  setAccountNo(event.target.value);
};

  // Function to fetch user details based on account number
  const fetchUserDetails = async (accountNo) => {
    try {
      const res = await axios.get(`https://103.50.212.98/api/Insurance/forSearchButton?AccountNo=${accountNo}`);
      const userData = res.data.result;
      console.log(userData);
      setUserData(userData);
    } catch (error) {
      console.log(error);
    }
  };

   // Prevent accidental value changes on scroll
   const handleScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div>
             <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>
       
        </div>
        {/* <!-- #END# Search Bar -->
   
  <!-- Top Bar --> */}
       <AdminTopBar/>
        {/* <!-- #Top Bar --> */}


        
{/* sideBar */}


<AdminSideBar/>
{/* end sideBar */}

        <section className="content">
             <Link to="/AdminDashBoard">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">
            
            </div>


          
              {/* form starts */}
            {/* <!-- Masked Input --> */}

            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Insurance Form</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">
                      <Form onSubmit={handleSubmit(onSubmit)}>
                                             


                        <div className="col-md-8">
                          <b>Account Number (खाते क्रमांक)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                name='accountNo'
                                className="form-control date"
                                placeholder="Enter Account Number"
                                {...register("accountNo")}
                                onChange={handleAccountNoChange}
                              />
                              <button type="button"
                               onClick={() => fetchUserDetails(accountNo)}
                              className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton">Search</button>
                            </div>
                          </div>
                        </div>
                        {/* <button type="button" className="btn btn-primary ">Search</button> */}



                       


                        <div className="col-md-8">
                          <b>Full Name (पूर्ण नाव)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                name='fullName'
                                className="form-control date"
                                placeholder="Enter Full Name (पूर्ण नाव)"
                                {...register("fullName")}
                                value={userData ? userData.fullName : ''}
                                readOnly // Make this field read-only
                              />
                            </div>
                          </div>
                        </div>



                        <div className="col-md-8">
                          <b>Date (तारीख)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                           
                              <input
                                type="date"
                                name='date'
                                className="form-control date"
                                {...register("date", { required: 'Date is required' })} // Adding required validation

                              />
                            </div>
                          </div>
                          <p style={{ color: "red" }}>{errors.date && errors.date.message}</p> {/* Display error message */}
                        </div>

                

                        <div className="col-md-8">
                          <b>Insurance Amount (Monthly)</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                           
                              <input
                                type="number"
                                name='insuranceAmount'
                                className="form-control date"
                                placeholder='Enter Insurance Amount'
                               
                                {...register("insuranceAmount", { required: 'Insurance Amount is required' })} // Adding required validation
                              />
                               
                            </div>
                          </div>
                          <p style={{ color: "red" }}>{errors.insuranceAmount && errors.insuranceAmount.message}</p> {/* Display error message */}
                         
                        </div>


                        <div className="col-md-8">
                          <b>Total Insurance Amount</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                name='totalInsuranceAmount'
                                placeholder='Enter Total Insurance Amount'
                                {...register("totalInsuranceAmount")}
                                value={userData ? userData.totalAmount : ''}
                                readOnly // Make this field read-only
                              />
                            </div>
                          </div>
                        </div>


                             <div className="col-md-8">
                          <b>Receipt Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                name='recepitNo'
                                className="form-control date"
                                placeholder='Enter Receipt Number'
                                {...register("recepitNo")}
                              />
                            </div>
                          </div>
                        </div> 



              
                        <Button type="submit" variant='primary' className="btn bg-indigo btn-block btn-lg waves-effect">ADD</Button>
                      </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}




  {/* Table start */}
  {/* <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>New Loan Member List</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                        
                       



                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                          
                          <tbody>
                          <InsurenceData  formData={formData}/>


                      
                          </tbody>
                        </table>
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_1_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing 1 to 10 of 57 entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_1_paginate"
                        >
                          <ul className="pagination">
                            <li
                              className="paginate_button previous disabled"
                              id="DataTables_Table_1_previous"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="0"
                                tabIndex="0"
                              >
                                Previous
                              </a>
                            </li>
                            <li className="paginate_button active">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="1"
                                tabIndex="0"
                              >
                                1
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="2"
                                tabIndex="0"
                              >
                                2
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="3"
                                tabIndex="0"
                              >
                                3
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="4"
                                tabIndex="0"
                              >
                                4
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="5"
                                tabIndex="0"
                              >
                                5
                              </a>
                            </li>
                            <li className="paginate_button ">
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="6"
                                tabIndex="0"
                              >
                                6
                              </a>
                            </li>
                            <li
                              className="paginate_button next"
                              id="DataTables_Table_1_next"
                            >
                              <a
                                href="#"
                                aria-controls="DataTables_Table_1"
                                data-dt-idx="7"
                                tabIndex="0"
                              >
                                Next
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
 
         {/*  Table end */}



          </div>
        </section>
      </div>
    </div>
  )
}

export default Insurence
