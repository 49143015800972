import axios from 'axios';
import React, { useState } from 'react'

const CloseRDmodal = ({ handleEdit ,handleInputChange,formData,}) => {
  const [endDate, setEndDate] = useState('');
  const [rdmonthlyAmount, setRdmonthlyAmount] = useState('');
  const [interestAmount, setInterestAmount] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [days, setDays] = useState('');
  const [note, setNote] = useState(''); // New state for note
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false); // New state for disabling the icon


  const handleResetForm = () => {
    handleInputChange({ target: { name: 'rdid', value: '' } });
    handleInputChange({ target: { name: 'accountNo', value: '' } });
    handleInputChange({ target: { name: 'startDate', value: '' } });
    handleInputChange({ target: { name: 'endDate', value: '' } });
    handleInputChange({ target: { name: 'days', value: '' } });
    handleInputChange({ target: { name: 'rdmonthlyAmount', value: '' } });
    handleInputChange({ target: { name: 'totalAmount', value: '' } });
    handleInputChange({ target: { name: 'chequeNumber', value: '' } });
    handleInputChange({ target: { name: 'receiptNo', value: '' } });
  };

  const handleDateButtonClick = () => {
    axios
      .get('https://103.50.212.98/api/RdDetails/GetRdAmountForClose', {
        params: {
          AccountNo: formData.accountNo,
          UserDate: endDate,
        },
      })
      .then(response => {
        console.log('API response:', response.data);
        const { totalAmount, interestAmount, days } = response.data.result || {};
        setRdmonthlyAmount(formData.rdmonthlyAmount); // Assuming rdmonthlyAmount remains the same
        setInterestAmount(interestAmount || 0); // Ensure 0 if undefined
        setTotalAmount(totalAmount || 0); // Ensure 0 if undefined
        setDays(days || 0); // Ensure 0 if undefined
      })
      .catch(error => {
        console.error('Error fetching RD amount:', error.response ? error.response.data : error.message);
      });
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = () => {
    setIsSubmitDisabled(true); // Disable the delete_sweep icon
    const payload = {
      rdid: formData.rdid || 0,
      accountNo: formData.accountNo || 0,
      fullName: formData.fullName || '',
      rdno: formData.rdno || '',
      rdmonthlyAmount: rdmonthlyAmount || 0,
      startDate: formData.startDate || '',
      receiptNo: formData.receiptNo || 0,
      isDelete: true,
      changeInterestId: 0,
      endDate: endDate || '',
      chequeNumber: formData.chequeNumber || '',
      previosBalance: formData.previosBalance || 0,
      totalAmount: totalAmount || 0,
      interestAmount: interestAmount || 0,
      interestId: 0,
      modifiedDate: new Date().toISOString(),
      note: note || '',
      isActive: true,
      days: days || 0
    };

    axios
      .post('https://103.50.212.98/api/RdDetails/RDclose', payload, {
        headers: {
          'accept': '*/*',
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        console.log('POST response:', response.data);
        // Handle success (e.g., close modal, show notification)
        alert('RD closed successfully!');
        handleResetForm();
      })
      .catch(error => {
        console.error('Error posting RD close data:', error.response ? error.response.data : error.message);
      });
  };


  return (
    <div>
    {/* <i  type="button" onClick={handleEdit} className='material-icons delete-icon' data-toggle="modal" data-target="#exampleModal">delete_sweep</i> */}
    <button
        type="button"
        onClick={handleEdit}
        className={`material-icons delete-icon ${isSubmitDisabled ? 'disabled' : ''}`}
        data-toggle="modal"
        data-target="#exampleModal"
        disabled={isSubmitDisabled} // Disable the icon when true
        style={{ pointerEvents: isSubmitDisabled ? 'none' : 'auto', opacity: isSubmitDisabled ? 0.5 : 1 }}
      >
        delete_sweep
      </button>


{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog" role="document">
<div className="modal-content">
  <div className="modal-header">
    <h5 className="modal-title" id="exampleModalLabel">Close RD</h5>
    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div className="modal-body">
    



     {/* modal form start */}
     
     <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="card modalFormCard">
              <div className="header">
                <h2>Close RD</h2>
              </div>
              <div className="body">
                <div className="demo-masked-input">
                  <div className="row clearfix">
                   
                 
                 
                  <div className="col-md-8">
                          <b>ID</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                placeholder="Enter id"
                                value={formData.rdid}
                                  onChange={(e) => handleInputChange(e)}
                                  name="rdid" id="rdid"
                              />
                            </div>
                          </div>
                        </div>


                        <div className="col-md-8">
                          <b>Account Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                // placeholder="Enter id"
                                value={formData.accountNo}
                                  onChange={(e) => handleInputChange(e)}
                                  name="accountNo" id="accountNo"
                              />
                            </div>
                          </div>
                        </div>



                        <div className="col-md-8">
                       <b>Start Date</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">date_range</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="date"
                             className="form-control date"
                             placeholder="Select Start Date"
                             name="startDate" id="startDate"
                             value={formData.startDate ? formData.startDate.split('T')[0] : ''}
                             readOnly
                             onChange={(e) => handleInputChange(e)}
                           />
                         </div>
                       </div>
                       </div>



                   <div className="col-md-8">
                    <b>End Date</b>
                  <div className="input-group" style={{ display: 'flex', alignItems: 'center' }}>
                      <span className="input-group-addon">
                        <i className="material-icons">date_range</i>
                      </span>
                      <div className="form-line">
                        <input
                          type="date"
                          className="form-control date"
                          placeholder="Select End Date"
                          name="endDate" id="endDate"
                          value={endDate}
                          onChange={handleEndDateChange}
                         
                        />
                      </div>
                      <button
                        type="button"
                        className="btn bg-indigo"
                       style={{ marginLeft: '10px' }}
                        onClick={handleDateButtonClick}
                      >
                        Date
                      </button>
                    </div>
                   </div>



                        <div className="col-md-8">
                          <b>Total Months</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                name="days" id="days"
                                placeholder='Enter total days'
                                value={days}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                        </div>



                        <div className="col-md-8">
                            <b>Interest Amount</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  className="form-control date"
                                  placeholder='Enter total days'
                                  value={interestAmount}
                                  onChange={(e) => handleInputChange(e)}
                                  name="interestAoumnt" id="interestAoumnt"

                                />
                              </div>
                            </div>
                          </div>
                      


                        <div className="col-md-8">
                          <b>RD Amount</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                placeholder='Enter FD amount'
                                name="rdmonthlyAmount" id="rdmonthlyAmount"
                                value={rdmonthlyAmount}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                        </div>

                      

                        <div className="col-md-8">
                          <b>Total Amount</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                placeholder='Enter Total amount'
                                value={totalAmount}
                                onChange={(e) => handleInputChange(e)}
                                name="totalAmount" id="totalAmount"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <b>cheque Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="text"
                                className="form-control date"
                                placeholder='Enter cheque number'
                                name="chequeNumber" id="chequeNumber"
                                value={formData.chequeNumber}
                                onChange={(e) => handleInputChange(e)}

                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <b>Receipt Number</b>
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">person</i>
                            </span>
                            <div className="form-line">
                              <input
                                type="number"
                                className="form-control date"
                                placeholder='Enter Reciept number'
                                name="receiptNo" id="receiptNo"
                                value={formData.receiptNo}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                        </div>


                   

                    <button type="button"  className="btn bg-indigo btn-block btn-lg waves-effect" onClick={handleSubmit}>Submit</button>
                    <button type="button" className="btn bg-indigo btn-block btn-lg waves-effect closeButton" data-dismiss="modal">Close</button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
   {/* Modal form ends */}


  </div>
</div>
</div>
</div>
{/* Modal End */}
</div>
  )
}

export default CloseRDmodal
