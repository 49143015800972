import React, { useState } from 'react'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { Link } from 'react-router-dom'
import TotalMembersDeleteModal from './TotalMembersDeleteModal'
import TotalMembersEdit from './TotalMembersEdit'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import TotalMemberData from './TotalMemberData'

const TotalMembers = () => {
  const [searchAccountNo, setSearchAccountNo] = useState('');
  const [memberData, setmemberData] = useState([]);
  const [formData, setFormData] = useState({
    totalAmount: '',
    interestAmount: '',
    days: '',
    fdId: '',
    fdYear: '',
    fdCertificateNo: '',
    accountNo: '',
    accountName: '',
    fdAmount: '',
    fdDate: '',
    fdCloseDate: '',
    reciptNo: '',
    chequeNo: '',
    changeInterestId: '',
    interestAoumnt: '',
    totalFdamount: ''

  });
  console.log("fd details me kia hai",memberData);

  const [editId, setEditId] = useState(null);
  const handleSearch = () => {
    axios.get(`https://103.50.212.98/api/Member/AccountNo?AccountNo=${searchAccountNo}`)
      .then(response => {
        const { data } = response;
        if (data && data.result) {
          setmemberData(data.result); // Set the result to memberData state
        } else {
          setmemberData([]); // No data found, so reset the memberData state
        }
      })
      .catch(error => {
        console.error('Error fetching FD details:', error);
        // Optionally, you can set an error state here or show an error message
      });
  };
 
  const handleEdit = (id) => {
    // Handle edit action
    const userToEdit = memberData.find(details => details.fdId === id);
        if (userToEdit) {
            setFormData({
              totalAmount: userToEdit.totalAmount,
              interestAmount: userToEdit.interestAmount,
              days: userToEdit.days,
              fdId: userToEdit.fdId,
              fdYear: userToEdit.fdYear,
              fdCertificateNo: userToEdit.fdCertificateNo,
              accountNo: userToEdit.accountNo,
              accountName: userToEdit.accountName,
              fdAmount: userToEdit.fdAmount,
              fdDate: userToEdit.fdDate,
              fdCloseDate: userToEdit.fdCloseDate,
              reciptNo: userToEdit.reciptNo,
              chequeNo: userToEdit.chequeNo,
              changeInterestId: userToEdit.changeInterestId,
              interestAoumnt: userToEdit.interestAmount,
              totalFdamount: userToEdit.totalFdamount
                // name: studentToEdit.name,
                // age: studentToEdit.age,
                // fees: studentToEdit.fees
            });
            setEditId(id);
        }
};
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData({
      ...formData,
      [name]: value
  });
};
 
  return (
    <div>
    <div className="theme-red">
 {/* <!-- Page Loader --> */}
 {/* <Loader /> */}
 {/* <!-- #END# Page Loader -->


<!-- Search Bar --> */}
 <div className="search-bar">
   <div className="search-icon">
     <i className="material-icons">search</i>
   </div>
   <input type="text" placeholder="START TYPING..." />
   <div className="close-search">
     <i className="material-icons">close</i>
   </div>

 </div>
 {/* <!-- #END# Search Bar -->



<!-- Top Bar --> */}
<AdminTopBar/>
 {/* <!-- #Top Bar --> */}


 
{/* sideBar */}


<AdminSideBar  />
{/* end sideBar */}

 <section className="content">
      <Link to="/AdminDashBoard">  <h4 >DASHBOARD</h4></Link>
   <div className="container-fluid">
     <div className="block-header">
     
     </div>

   
       {/* form starts */}
     {/* <!-- Masked Input --> */}



     <div className="row clearfix">
       <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
         <div className="card">
           <div className="header">
             <h2> Member Account Detail</h2>
           </div>
           <div className="body">
             <div className="demo-masked-input">
               <div className="row clearfix">
                
  
                 <div className="col-md-8">
                   <b>Search</b>
                   <div className="input-group">
                     <span className="input-group-addon">
                       <i className="material-icons">person</i>
                     </span>
                     <div className="form-line">
                       <input
                         type="number"
                         name='accountNo'
                         className="form-control date"
                         placeholder="Enter Account number"
                         value={searchAccountNo}
                         onChange={(e) => setSearchAccountNo(e.target.value)}
                       />
                     </div>
                   </div>
                 </div>

                 <Button type="button"  onClick={handleSearch}  variant='primary' className="btn btn-block btn-lg waves-effect">Submit</Button>

               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
     {/* <!-- #END# Masked Input --> */}
     {/* form starts */}




{/* Table start */}

<div className="row clearfix">
       <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
         <div className="card">
           <div className="header">
             <h2>Member Account Detail</h2>
           
           </div>
           <div className="body">
             <div className="table-responsive">
               <div
                 id="DataTables_Table_1_wrapper"
                 className="dataTables_wrapper form-inline dt-bootstrap"
               >
                
              
                 {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}


                 <table
                   className="table table-bordered table-striped table-hover dataTable js-exportable"
                   id="DataTables_Table_1"
                   role="grid"
                   aria-describedby="DataTables_Table_1_info"
                 >
                 
                 {/* <TotalMemberData memberData={memberData}/> */}
                <TotalMemberData memberData={memberData} handleEdit={handleEdit} formData={formData} handleInputChange={handleInputChange}/>
                  
                 </table>
                 <div
                   className="dataTables_info"
                   id="DataTables_Table_1_info"
                   role="status"
                   aria-live="polite"
                 >
                   Showing 1 to 10 of 57 entries
                 </div>
                 <div
                   className="dataTables_paginate paging_simple_numbers"
                   id="DataTables_Table_1_paginate"
                 >
                   <ul className="pagination">
                     <li
                       className="paginate_button previous disabled"
                       id="DataTables_Table_1_previous"
                     >
                       <a
                         href="#"
                         aria-controls="DataTables_Table_1"
                         data-dt-idx="0"
                         tabIndex="0"
                       >
                         Previous
                       </a>
                     </li>
                     <li className="paginate_button active">
                       <a
                         href="#"
                         aria-controls="DataTables_Table_1"
                         data-dt-idx="1"
                         tabIndex="0"
                       >
                         1
                       </a>
                     </li>
                     <li className="paginate_button ">
                       <a
                         href="#"
                         aria-controls="DataTables_Table_1"
                         data-dt-idx="2"
                         tabIndex="0"
                       >
                         2
                       </a>
                     </li>
                     <li className="paginate_button ">
                       <a
                         href="#"
                         aria-controls="DataTables_Table_1"
                         data-dt-idx="3"
                         tabIndex="0"
                       >
                         3
                       </a>
                     </li>
                     <li className="paginate_button ">
                       <a
                         href="#"
                         aria-controls="DataTables_Table_1"
                         data-dt-idx="4"
                         tabIndex="0"
                       >
                         4
                       </a>
                     </li>
                     <li className="paginate_button ">
                       <a
                         href="#"
                         aria-controls="DataTables_Table_1"
                         data-dt-idx="5"
                         tabIndex="0"
                       >
                         5
                       </a>
                     </li>
                     <li className="paginate_button ">
                       <a
                         href="#"
                         aria-controls="DataTables_Table_1"
                         data-dt-idx="6"
                         tabIndex="0"
                       >
                         6
                       </a>
                     </li>
                     <li
                       className="paginate_button next"
                       id="DataTables_Table_1_next"
                     >
                       <a
                         href="#"
                         aria-controls="DataTables_Table_1"
                         data-dt-idx="7"
                         tabIndex="0"
                       >
                         Next
                       </a>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
  {/*  Table end */}










   </div>
 </section>
</div>
</div>
  )
}

export default TotalMembers
