import React, { useState } from 'react'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { Link } from 'react-router-dom'
// import RegularLoanHistoryDeleteModal from './RegularLoanHistoryDeleteModal'
// import RegularLoanHistoryEditModal from './RegularLoanHistoryEditModal'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Form } from 'react-bootstrap'
import RegularLoanHistoryData from './RegularLoanHistoryData'

const RegularLoanHistory = () => {

  const [accountNo, setAccountNo] = useState('');
  const [loanData, setLoanData] = useState([]);

  const handleSubmit = async () => {
    try {
      const response = await axios.get(`https://103.50.212.98/api/RegularLoanHistory/getUserLoanHIstory?accountNo=${accountNo}`);
      setLoanData(response.data.result);
    } catch (error) {
      console.error('Error fetching loan data:', error);
    }
  };

  const handleInputChange = (event) => {
    setAccountNo(event.target.value);
  };
 
  
  return (
    <div>
      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
   
   
   
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>

        </div>
        {/* <!-- #END# Search Bar -->
   
   
   
   
  <!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}



        {/* sideBar */}


        <AdminSideBar />
        {/* end sideBar */}

        <section className="content">
          <Link to="/AdminDashBoard">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">

            </div>


            {/* form starts */}
            {/* <!-- Masked Input --> */}



            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>New Loan Trans History</h2>
                  </div>
                  <div className="body">
                    <div className="demo-masked-input">
                      <div className="row clearfix">



                        <Form >


                          <div className="col-md-8">
                            <b>Account No</b>
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="material-icons">person</i>
                              </span>
                              <div className="form-line">
                                <input
                                  type="number"
                                  name='accountNo'
                                  className="form-control date"
                                  placeholder="Enter Account Number"
                                  value={accountNo}
                                  onChange={handleInputChange}
                                />
                              </div>

                            </div>
                          </div>



                          <button type="button" onClick={handleSubmit} className="btn bg-indigo btn-block btn-lg waves-effect">Submit</button>
                        </Form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- #END# Masked Input --> */}
            {/* form starts */}




            {/* Table start */}

            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2> NEW LOAN History</h2>
                   
                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                      


                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}


                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                         <RegularLoanHistoryData loanData={loanData}/>
                        </table>
                       
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}






          </div>
        </section>
      </div>
    </div>
  )
}

export default RegularLoanHistory
