import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import axios from 'axios'

const MonthlyEmiEdit = () => {


  const [accountNumber, setAccountNumber] = useState('');
  const [emiDetails, setEmiDetails] = useState({
    shares: '',
    monthlyDeposit: '',
    insurance: '',
    regLoan: '',
    regLoanInterest: '',
    emgLoan: '',
    emgLoanInterest: '',
    rdAmount: '',
    emiDate: ''
  });


  const [postData, setPostData] = useState({
    shares: '',
    monthlyDeposit: '',
    insurance: '',
    regLoan: '',
    regLoanInterest: '',
    emgLoan: '',
    emgLoanInterest: '',
    rdAmount: '',
    emiDate: ''
  });

  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://103.50.212.98/api/DeductionListMonthly/AccountNo?AccountNo=${accountNumber}`);
      if (response.data.statusCode === 200) {
        setEmiDetails(response.data.result);
      } else {
        alert('No data found for the entered account number');
      }
    } catch (error) {
      console.error('Error fetching EMI details', error);
      alert('An error occurred while fetching EMI details');
    }
  };



  const handleSubmit = async () => {
    const payload = {
      accountNo: accountNumber,
      ...postData
    };

    try {
      const response = await axios.post('https://103.50.212.98/api/DeductionListMonthly/Post', payload);
      if (response.status === 200) {
        alert('Data successfully submitted');
        setPostData({
          shares: '',
          monthlyDeposit: '',
          insurance: '',
          regLoan: '',
          regLoanInterest: '',
          emgLoan: '',
          emgLoanInterest: '',
          rdAmount: '',
          emiDate: ''
        });
        setAccountNumber('');
      } else {
        alert('Failed to submit data');
      }
    } catch (error) {
      console.error('Error submitting data', error);
      alert('An error occurred while submitting data');
    }
  };

  const handlePostDataChange = (e) => {
    const { name, value } = e.target;
    setPostData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };


  

  return (
    <div>
    <div className="theme-red">
     {/* <!-- Page Loader --> */}
     {/* <Loader /> */}
     {/* <!-- #END# Page Loader -->


<!-- Search Bar --> */}
     <div className="search-bar">
       <div className="search-icon">
         <i className="material-icons">search</i>
       </div>
       <input type="text" placeholder="START TYPING..." />
       <div className="close-search">
         <i className="material-icons">close</i>
       </div>
    
     </div>
     {/* <!-- #END# Search Bar -->




<!-- Top Bar --> */}
    <AdminTopBar/>
     {/* <!-- #Top Bar --> */}


     
{/* sideBar */}


<AdminSideBar/>
{/* end sideBar */}

     <section className="content">
          <Link to="/AdminDashBoard">  <h4 >DASHBOARD</h4></Link>
       <div className="container-fluid">
         <div className="block-header">
         
         </div>

       
           {/* form starts */}
         {/* <!-- Masked Input --> */}

         <div className="row clearfix">
           <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
             <div className="card">
               <div className="header">
                 <h2>Monthly EMI Edit</h2>
               </div>
               <div className="body">
                 <div className="demo-masked-input">
                   <div className="row clearfix">
                    
                    
                      <div className="col-md-8">
                       <b>Account Number (खाते क्रमांक)</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                                 <div className="col-4 SearchInputField">
                                     <input className="form-control date "
                                      value={accountNumber}
                                      onChange={(e) => setAccountNumber(e.target.value)}
                                     type="number" placeholder="Enter Account Number" />
                                     <button type="button"
                                     onClick={handleSearch}
                                     className="btn bg-indigo btn-block btn-lg waves-effect primaryButton secondaryButton ">Search</button>
                                </div>
                          </div>
                       </div>
                     </div> 

                     {/* <button type="button" className="btn btn-primary ">Search</button> */}
                 

                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       <b>Current Status (Deduction)</b> <br/> <br/>
                       <b>Shares</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Shares"
                             value={emiDetails.shares}
                                readOnly
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                   <b>Update Amount (Deduction)</b>  <br/>   <br/>  <br/>
                       {/* <b>Full Name (पूर्ण नाव)</b> */}
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Shares"
                             name="shares"
                             value={postData.shares}
                             onChange={handlePostDataChange}
                           />
                           
                         </div>
                       </div>
                     </div> 



                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       
                       <b>Monthly Deposit</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Monthly Deposite"
                             value={emiDetails.monthlyDeposit}
                             readOnly
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Monthly Deposite"
                             name="monthlyDeposit"
                             value={postData.monthlyDeposit}
                             onChange={handlePostDataChange}
                           />
                           
                         </div>
                       </div>
                     </div> 



                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       
                       <b>Insurance</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Insurence"
                             value={emiDetails.insurance}
                             readOnly
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Insurence"
                             name="insurance"
                             value={postData.insurance}
                             onChange={handlePostDataChange}
                           />
                           
                         </div>
                       </div>
                     </div> 



                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">                      
                       <b>Regular Loan</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Regular Loan"
                             value={emiDetails.regLoan}
                             readOnly
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Regular Loan"
                             name="regLoan"
                             value={postData.regLoan}
                             onChange={handlePostDataChange}
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">                      
                       <b>Regular Loan Interest</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Regular Loan"
                             value={emiDetails.regLoanInterest}
                                readOnly
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Regular Loan"
                             name="regLoanInterest"
                             value={postData.regLoanInterest}
                             onChange={handlePostDataChange}

                           />
                           
                         </div>
                       </div>
                     </div> 





                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       
                       <b>Emergency Loan</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Emergency Loan"
                             value={emiDetails.emgLoan}
                                readOnly
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">                    
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Emergency Loan"
                             name="emgLoan"
                             value={postData.emgLoan}
                             onChange={handlePostDataChange}
                          />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                       
                       <b>Emergency Loan Interest</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Regular Loan"
                             value={emiDetails.emgLoanInterest}
                                readOnly
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                     
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Regular Loan"
                             name="emgLoanInterest"
                             value={postData.emgLoanInterest}
                             onChange={handlePostDataChange}
                           />
                           
                         </div>
                       </div>
                     </div> 




                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">                      
                       <b>RD</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Current Status Regular Loan"
                             value={emiDetails.rdAmount}
                                readOnly
                           />
                           
                         </div>
                       </div>
                     </div> 


                     <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">                    
                       {/* <b>Full Name (पूर्ण नाव)</b> */} <br/>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="number"
                             className="form-control date"
                             placeholder="Enter Update Amount Regular Loan"
                          
                             name="rdAmount"
                             value={postData.rdAmount}
                             onChange={handlePostDataChange}
                          />
                           
                         </div>
                       </div>
                     </div> 



                     <div className="col-md-8">
                       <b>Date</b>
                       <div className="input-group">
                         <span className="input-group-addon">
                           <i className="material-icons">person</i>
                         </span>
                         <div className="form-line">
                           <input
                             type="date"
                             className="form-control date"
                             name="emiDate"
                             value={postData.emiDate}
                             onChange={handlePostDataChange}
                           />
                         </div>
                       </div>
                     </div> 
                   

                               
                   </div>
                   <Button type="button" onClick={handleSubmit}  variant='primary' className="btn btn-block btn-lg waves-effect">Submit</Button>

                 </div>
               </div>
             </div>
           </div>
         </div>
         {/* <!-- #END# Masked Input --> */}
         {/* form starts */}



       </div>
     </section>
   </div>
 </div>
  )
}

export default MonthlyEmiEdit
