import React, { useEffect, useState } from 'react'
import AdminTopBar from '../../AdminTopBar/AdminTopBar'
import AdminSideBar from '../../AdminSideBar/AdminSideBar'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { Button, Form } from 'react-bootstrap'
import "./DeathMember.css"
import axios from 'axios'
import DeathMemberData from './DeathMemberData'

const DeathMember = () => {
//  https://103.50.212.98/api/DeadMember/post
// https://103.50.212.98/api/DeadMember/AccountNo?AccountNo=${accountNo}
  // const { handleSubmit, register, reset, formState: { errors } } = useForm();
  // const [users, setUsers] = useState([])
  // const [userData, setUserData] = useState(null); // State to store user data (auto fill fields)
 
  

  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [deathDateError, setDeathDateError] = useState(false);
  const [monthlyDepositError, setMonthlyDepositError] = useState(false);
  const [insuranceError, setInsuranceError] = useState(false);
  const [payableAmountError, setPayableAmountError] = useState(false);
  const [payToError, setPayToError] = useState(false);
  const [sharesError, setSharesError] = useState(false);

 
 
  const [formData, setFormData] = useState({
    accountNo: '',
    accountName: '',
    salaryAccount: '',
    deathDate: '',
    shares: '',
    monthlyDeposit: '',
    insurance: '',
    _10AddedAmount: '',
    rd: '',
    fd: '',
    regularLoan: '',
    regLoanInterest: '',
    emergancyLoan: '',
    emgLoanInterest: '',
    totalAmount: '',
    payableAmount: '',
    chequeNo: '',
    chequeDate: '',
    payTo: '',
    nomineeName1: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchUserDetails = async (accountNo) => {
    try {
      const res = await axios.get(`https://103.50.212.98/api/DeadMember/AccountNo?AccountNo=${accountNo}`);
      const userData = res.data.result;
      setUserData(userData);
      setFormData({
        ...formData,
        accountName: userData.accountName,
        shares: userData.shares,
        monthlyDeposit: userData.monthlyDeposit,
        insurance: userData.insurance,
        _10AddedAmount: userData._10AddedAmount,
        rd: userData.rd,
        fd: userData.fd,
        regularLoan: userData.regularLoan,
        regLoanInterest: userData.regLoanInterest,
        emergancyLoan: userData.emergancyLoan,
        emgLoanInterest: userData.emgLoanInterest,
        // totalAmount: userData.totalAmount,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateButtonClick = async () => {
    if (!formData.accountNo || !formData.deathDate) {
      alert("Account number and death date are required");
      return;
    }
    try {
      const res = await axios.get(`https://103.50.212.98/api/DeadMember/InterestDetails?AccountNo=${formData.accountNo}&userdate=${formData.deathDate}`);
      const interestData = res.data.result;
      setFormData((prevFormData) => {
        const updatedFormData = {
          ...prevFormData,
          regLoanInterest: interestData.regularLoanInterest,
          emgLoanInterest: interestData.emgLoanInterest,
          fd: interestData.fdAmount,
          rd: interestData.rdAmount
        };
        
        const sum1 = parseFloat(updatedFormData.shares) + parseFloat(updatedFormData.monthlyDeposit) + parseFloat(updatedFormData._10AddedAmount);
        const sum2 = parseFloat(updatedFormData.rd) + parseFloat(updatedFormData.fd) + parseFloat(updatedFormData.regularLoan) + parseFloat(updatedFormData.regLoanInterest) + parseFloat(updatedFormData.emergancyLoan) + parseFloat(updatedFormData.emgLoanInterest);

        const payableAmount = sum1 - sum2;
        const totalAmount = sum1 - sum2

        return {
          ...updatedFormData,
          payableAmount: payableAmount,
          totalAmount: totalAmount
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submitted!');
    console.log('Form Data:', formData);

    let hasError = false;

    if (!formData.deathDate) {
      setDeathDateError(true);
      hasError = true;
    } else {
      setDeathDateError(false);
    }

    if (!formData.monthlyDeposit) {
      setMonthlyDepositError(true);
      hasError = true;
    } else {
      setMonthlyDepositError(false);
    }

    if (!formData.insurance) {
      setInsuranceError(true);
      hasError = true;
    } else {
      setInsuranceError(false);
    }

    if (!formData.payTo) {
      setPayToError(true);
      hasError = true;
    } else {
      setPayToError(false);
    }

    if (!formData.shares) {
      setSharesError(true);
      hasError = true;
    } else {
      setSharesError(false);
    }

    if (hasError) {
      return;
    }

    try {
      const response = await axios.post('https://103.50.212.98/api/DeadMember/post', formData);
      console.log('Form data submitted successfully:', response.data);
      alert("Form data submitted successfully");
      setFormData({
        accountNo: '',
        accountName: '',
        salaryAccount: '',
        deathDate: '',
        shares: '',
        monthlyDeposit: '',
        insurance: '',
        _10AddedAmount: '',
        rd: '',
        fd: '',
        regularLoan: '',
        regLoanInterest: '',
        emergancyLoan: '',
        emgLoanInterest: '',
        totalAmount: '',
        payableAmount: '',
        chequeNo: '',
        chequeDate: '',
        payTo: '',
        nomineeName1: ''
      });
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };


  const fetchUsers = async (url) => {
    try {
      const res = await axios.get(url);
      const Tdata = res.data;
      console.log(Tdata, " Dead member table data");
      setUsers(Tdata);
    } catch (error) {
      console.log(error);
    }
  };

  const API = "https://103.50.212.98/api/DeadMember/get";

  useEffect(() => {
    fetchUsers(API);
  }, []);


  return (
    <div>
      <div className="theme-red">
        {/* <!-- Page Loader --> */}
        {/* <Loader /> */}
        {/* <!-- #END# Page Loader -->
   
  <!-- Search Bar --> */}
        <div className="search-bar">
          <div className="search-icon">
            <i className="material-icons">search</i>
          </div>
          <input type="text" placeholder="START TYPING..." />
          <div className="close-search">
            <i className="material-icons">close</i>
          </div>

        </div>
        {/* <!-- #END# Search Bar -->
  <!-- Top Bar --> */}
        <AdminTopBar />
        {/* <!-- #Top Bar --> */}
        {/* sideBar */}

       <AdminSideBar />
        {/* end sideBar */}

        <section className="content">
          <Link to="/AdminDashBoard">  <h4 >DASHBOARD</h4></Link>
          <div className="container-fluid">
            <div className="block-header">
            </div>

            {/* form starts */}
            {/* <!-- Masked Input --> */}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="DedictionForm">

                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                      <div className="header">
                        <h2>मृत झालेल्या सभासदांचा गोषवारा</h2>
                      </div>
                      <div className="body">
                        <div className="demo-masked-input">
                          <div className="row clearfix">




                            <div className="col-md-8">
                              <b>Account Number (खाते क्रमांक)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <div className="col-4">
                                    <input name='accountNo'
                                     value={formData.accountNo}
                                     onChange={handleInputChange}
                                    className="form-control date" type="number" placeholder="Enter Account Number (खाते क्रमांक)" 
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <button
                             onClick={() => fetchUserDetails(formData.accountNo)}
                            type="button" className="btn bg-indigo ">Search</button>




                            <div className="col-md-8">
                              <b>सभासदाचे नाव</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='accountName'
                                    type="text"
                                    className="form-control date"
                                    placeholder="सभासदाचे नाव"
                                    value={formData.accountName}
                                    readOnly
                                    // onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>




                            <div className="col-md-8">
                              <b>Salary Account</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='salaryAccount'
                                    type="text"
                                    className="form-control date"
                                    placeholder="सभासदाचे नाव"
                                    value={formData.salaryAccount}
                                     onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>


                           

                          

                            <div className="col-md-8 ">
                              <b>Current Deduction</b> <br /> <br />
                              <b>भाग (शेअर्स) (share)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                
                                  {/* <Form.Control */}
                                  <input
                                    type="number"
                                    className={`form-control ${sharesError ? 'is-invalid' : ''}`}
                                    placeholder='Enter भाग (शेअर्स) '
                                    name='shares'
                                    value={formData.shares}
                                    readOnly
                                    // onChange={handleInputChange}
                                  />

                                </div>
                              </div>
                              {sharesError && <p style={{ color: "red" }}>This is required</p>}
                            </div>






                            <div className="col-md-8 ">

                              <b>मासिक ठेव(monthly deposite)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  {/* <Form.Control */}
                                  <input
                                    type="number"
                                    className={`form-control ${monthlyDepositError ? 'is-invalid' : ''}`}
                                    placeholder='Enter मासिक ठेव '
                                    name='monthlyDeposit'
                                    value={formData.monthlyDeposit}
                                    readOnly
                                    // onChange={handleInputChange}
                                  />

                                </div>
                              </div>
                              {monthlyDepositError && <p style={{ color: "red" }}>Date is required</p>}
                            </div>


                    
                            <div className="col-md-8">

                              <b>सभासद कल्याण निधी(insurence)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                 
                                   {/* <Form.Control */}
                                   <input
                                    type="number"
                                    className={`form-control ${insuranceError ? 'is-invalid' : ''}`}
                                    placeholder="सभासद कल्याण निधी"
                                    name='insurance'
                                    value={formData.insurance}
                                    readOnly
                                    // onChange={handleInputChange}
                                 />

                                </div>
                              </div>
                              {insuranceError && <p style={{ color: "red" }}>Date is required</p>}
                            </div>


                    
                            <div className="col-md-8">
                              <b>10 Multiply Added Amount</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='_10AddedAmount'
                                    type="number"
                                    className="form-control date"
                                    placeholder="Enter 10 Multiply Added Amount"
                                    value={formData._10AddedAmount}
                                    readOnly
                                    // onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>



                            <div className="col-md-8">
                              <b>मृत्यू दिनांक</b>
                              <div className="input-group" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line" style={{ flex: 1 }}>
                                  <input
                                    type="date"
                                    className={`form-control ${deathDateError ? 'is-invalid' : ''}`}
                                    placeholder="मृत्यू दिनांक"
                                    name='deathDate'
                                    value={formData.deathDate}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <button
                                  onClick={handleDateButtonClick}
                                  type="button"
                                  className="btn bg-indigo"
                                  style={{ marginLeft: '10px' }}
                                >
                                  Date
                                </button>
                              </div>
                              {deathDateError && <p style={{ color: "red" }}>Date is required</p>}
                            </div>


                            <div className="col-md-8">

                              <b>सभासद बचत योजना (RD)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='rd'
                                    type="number"
                                    className="form-control date"
                                    placeholder="सभासद बचत योजना (RD)"
                                    value={formData.rd}
                                    readOnly
                                    // onChange={handleInputChange}
                                  />

                                </div>
                              </div>
                            </div>


                        

                            <div className="col-md-8">

                              <b>कायम ठेवी (FD)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='fd'
                                    type="number"
                                    className="form-control date"
                                    placeholder="कायम ठेवी (FD)"
                                    value={formData.fd}
                                    readOnly
                                    // onChange={handleInputChange}
                                  />

                                </div>
                              </div>
                            </div>


                            <div className="col-md-8">

                              <b>नियमित कर्जावरील व्याज(regular loan interest)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='regLoanInterest'
                                    type="number"
                                    className="form-control date"
                                    placeholder="नियमित कर्जावरील व्याज"
                                    value={formData.regLoanInterest}
                                    readOnly
                                    // onChange={handleInputChange}
                                  />

                                </div>
                              </div>
                            </div>
                       


                            <div className="col-md-8">

                              <b>आकस्मित कर्जावरील व्याज(emergency loan interest)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='emgLoanInterest'
                                    type="number"
                                    className="form-control date"
                                    placeholder="आकस्मित कर्जावरील व्याज"
                                    value={formData.emgLoanInterest}
                                    readOnly
                                    // onChange={handleInputChange}
                                  />

                                </div>
                              </div>
                            </div>



                            <div className="col-md-8">
                              <b>एकूण आकस्मित कर्ज (emergency loan)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='emergancyLoan'
                                    type="number"
                                    className="form-control date"
                                    placeholder="एकूण आकस्मित कर्ज"
                                    value={formData.emergancyLoan}
                                    readOnly
                                    // onChange={handleInputChange}
                                  />

                                </div>
                              </div>
                            </div>



                            <div className="col-md-8">

                              <b>एकूण नियमित कर्ज(regular loan)</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='regularLoan'
                                    type="number"
                                    className="form-control date"
                                    placeholder="एकूण नियमित कर्ज"
                                    value={formData.regularLoan}
                                    readOnly
                                    // onChange={handleInputChange}
                                  />

                                </div>
                              </div>
                            </div>


                    



                            <div className="col-md-8">

                              <b>Total Amount</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='totalAmount'
                                    type="number"
                                    className="form-control date"
                                    placeholder="Total Amount"
                                    value={formData.totalAmount}
                                    readOnly
                                    // onChange={handleInputChange}
                                 />

                                </div>
                              </div>
                            </div>


                     
                            <div className="col-md-8">
                              <b>Payable Amount</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">

                                   {/* <Form.Control */}
                                   <input
                                   name='payableAmount'
                                    type="number"
                                    className={`form-control ${payableAmountError ? 'is-invalid' : ''}`}
                                    placeholder='Enter Payable Amount'
                                    value={formData.payableAmount}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              {payableAmountError && <p style={{ color: "red" }}>This field is required</p>}
                            </div>


                            <div className="col-md-8">
                              <b>Cheque Number</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='chequeNo'
                                    type="number"
                                    className="form-control date"
                                    placeholder='Enter Cheque Number'
                                    value={formData.chequeNo}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="col-md-8">
                              <b>Cheque Date</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='chequeDate'
                                    type="date"
                                    className="form-control date"
                                    value={formData.chequeDate}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="col-md-8">
                              <b>Pay To</b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                 
                                  {/* <Form.Control */}
                                  <input
                                  name='payTo'
                                    type="text"
                                    className={`form-control ${payToError ? 'is-invalid' : ''}`}
                                    placeholder='Enter Pay To'
                                    value={formData.payTo}
                                    onChange={handleInputChange}
                                 />
                                </div>
                              </div>
                              {payToError && <p style={{ color: "red" }}>Date is required</p>}
                            </div>




                            <div className="col-md-8">
                              <b>Nominee Relation </b>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="material-icons">person</i>
                                </span>
                                <div className="form-line">
                                  <input
                                  name='nomineeName1'
                                    type="text"
                                    className="form-control date"
                                    value={formData.nomineeName1}
                                    onChange={handleInputChange}
                                 />
                                </div>
                              </div>
                            </div>


                           
                         
                          {/* <Button type="submit" variant='primary' className="btn btn-block btn-lg waves-effect">Submit</Button> */}
                          </div>
                          <Button type="submit" variant='primary' className="btn btn-block btn-lg waves-effect">Submit</Button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </Form>



                        {/* Table start */}

                        <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Dead Member Table</h2>

                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <div
                        id="DataTables_Table_1_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap"
                      >
                        
                        {/* <label>Show <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-control input-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> */}



                        <table
                          className="table table-bordered table-striped table-hover dataTable js-exportable"
                          id="DataTables_Table_1"
                          role="grid"
                          aria-describedby="DataTables_Table_1_info"
                        >
                          
                          <tbody>
                            <DeathMemberData Users={users} />

                      
                          </tbody>
                        </table>
                       
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Table end */}
 
          </div>
        </section>
      </div>
    </div>
  )
}

export default DeathMember
